<template>
    <div class="myAddress">
        <div class="addressList">
            <table cellspacing="0">
                <tr>
                    <th ref="111">收货人</th>
                    <th style="width:171px">所在区域</th>
                    <th style="width:201px">街道地址</th>
                    <th style="width:152px">联系电话</th>
                    <th style="width:200px">操作</th>
                </tr>
                <tr v-for="(im,ix) in addresslist" :key="ix">
                    <td>{{im.consignee_name}}</td>
                    <td>{{im.provice}}{{im.city}}{{im.area}}</td>
                    <td>{{im.address}}</td>
                    <td>{{im.consignee_phone}}</td>
                    <td><div class="flex alignCenter opear">
                        <span @click="setDefault(im,ix)" v-if="im.is_default+'' != '1'">设为默认</span>
                        <span @click="readaddress(im.id)">查看</span>
                        <span @click="remiveAddress(im,ix)" style="color: #FF2121">删除</span>
                    </div></td>
                </tr>
            </table>
        </div>
        <el-dialog
        :visible.sync="addAddressHidden"
        :show-close="false"
        :close-on-click-modal="false"
        custom-class="addAddress">
        <div class="addressBox">
            <my-add-address1 :callData="addressCall" ref="myaddaddress1"></my-add-address1>
            <div class="operBtn">
                <button class="back" @click="addAddressHidden = false">返回</button>
                <button class="surenext" @click="sureNext">确定</button>
            </div>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import myAddAddress1 from '@/components/my_addAddress1'
import { edit_address, del_address, address_detail_id } from '@/utils/Api/shop'
export default {
    name:'myAddress',
    components:{myAddAddress1},
    computed:{
        addresslist(){
            return this.$store.getters.addressList
        }
    },
    data(){
        return{
            addAddressHidden: false,
            addressCall:{},
            editid: null
        }
    },
    methods:{
        sureNext(){
            edit_address({
                id: this.editid,
                ...this.$refs.myaddaddress1.add_address
            }).then(res => {
                console.log(res)
                if(res.length < 1){
                    this.$refs.myaddaddress1.add_address = ''
                    this.$store.dispatch('user/address')
                    this.addAddressHidden = false
                    this.$message.success('地址编辑成功！')
                }
            })
        },
        readaddress(value){
            this.editid = value
            address_detail_id({id:value}).then(res => {
                this.addAddressHidden = true
                setTimeout(() => {
                    this.$refs.myaddaddress1.dataassign(res)   
                });
                this.addressCall = res
                return
                
            })
        },
        setDefault(im,ix){
            edit_address({
                ...im,
                is_default: 1
            }).then(res => {
                if(res.length < 1){
                    this.addresslist.forEach(im => {
                        im.is_default = 0
                    });
                    im.is_default = 1
                    this.$message.success('地址编辑成功！')
                }
            })
        },
        remiveAddress(im,ix){
            this.$confirm('是否删除此地址？',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(r => {
                del_address({id: im.id}).then(res => {
                    if(res.length < 1){
                        this.addresslist.splice(ix, 1)
                        this.$message.success('删除成功！')
                    }
                })
            })
        }
    },
}
</script>
<style lang="scss" scoped>
.operBtn{
    button{
        width: 79px;
        height: 33px;
        opacity: 1;
        font-size: 15px;
        border-radius: 3px;
    }
    .back{
        border: 1px solid #47d7e3;
        cursor: pointer;
        color: #fff;
        background: none;
        color: #47d7e3;
        margin-right: 22px;
    }
    .surenext{
        background: #47D7E3;
        cursor: pointer;
        color: #fff;
    }
}
.addressBox{
    width: 756px;
    height: 453px;
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
}
.opear{
    margin-left: 3px;
    span{
        font-size: 15px;
        color: #2290FF;
        border-right: 1px solid #C4C4C4;
        padding-right: 13px;
        padding-left: 10px;
        cursor: pointer;
        &:last-child{border: none;}
    }
}
.myAddress{
    .addressList{
        margin-left: 30px;
        margin-top: 18px;
        border: 1px solid #e8e8e8;
        table{
            width: 850px;
        }
        th{
            background: #FAFAFA;
            height: 55px;
        }
        td{
            height: 82px;
            border: 1px solid #E8E8E8;
        }
    }
}
</style>